@use '@/styles/utils' as *;

.companySection {
    column-gap: 1.6rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    padding: 1.8rem;
    position: relative;

    @media (width <= $container-width-min) {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        padding: 0;
    }
}

@use '@/styles/utils' as *;

.companyAndMissionUnit {
    position: relative;

    .initialImage {
        height: 280px;
        margin-bottom: 1.8rem;
        object-fit: cover;
        object-position: top;
        position: relative;
        width: 100%;

        @media (width <= $container-width-min) {
            height: auto;
            max-height: 300px;
        }
    }

    .copy {
        align-items: flex-start;
        background-color: $color-main;
        border-left: 2px solid var(--color-error);
        color: var(--color-base);
        display: flex;
        flex-direction: column;
        font-family: var(--poppins);
        gap: 1.6rem;
        padding: 2rem;
        position: relative;
        z-index: 1;

        @media (width <= $container-width-min) {
            margin: 2rem;
        }

        h3,
        h5 {
            font-family: var(--dm-serif-display);
        }

        h3 {
            color: $color-red-1;
        }

        .joinBtn {
            align-self: flex-end;
            background: none;
            border: none;
            color: var(--color-error);
            font-family: var(--poppins);
            margin: 0;
            padding: 0;

            svg {
                fill: var(--color-error);
                transition: 220ms fill ease-in-out;
            }

            &:hover {
                color: var(--color-base);

                svg {
                    fill: var(--color-base);
                }
            }
        }

        .bgImage {
            mix-blend-mode: difference;
            object-fit: cover;
            opacity: 0.04;
            z-index: -1;

            @media (width <= $mobile-nav-breakpoint) {
                display: none;
            }
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .initialImage,
        .copy {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .initialImage,
            .copy {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
